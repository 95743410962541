<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawerView"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    color="secondary"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        gradient="to bottom, rgba(0,0,0,0), rgba(107, 181, 255,.4)"
        v-bind="props"
      />
    </template>
    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-card
        class="ma-0"
      >
        <v-list-item>
          <v-img
            src="https://cdn.clinic.inf.br/public/clinic/logo.svg"
            contain
            max-height="35px"
            max-width="35px"
          />

          <v-list-item-content>
            <v-list-item-title
              class="text-h4 mx-5"
              v-text="profile.title"
            />
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
      dark
    >
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
    <v-row
      align="end"
      style="bottom: 5px; position: fixed; left: 10px;"
      no-gutters
    >
      <v-col
        class="pa-0"
      >
        <v-switch
          v-model="$vuetify.theme.dark"
          label="Dark mode"
          class="mx-3 mb-3 pa-0"
          color="white"
          hide-details
        />
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import { mapState, mapGetters, mapActions } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [
        {
          icon: 'mdi-monitor-dashboard',
          title: 'dashboard',
          to: '/',
        },
        {
          icon: 'mdi-server',
          title: 'awsServers',
          to: '/aws-servers',
        },
        {
          icon: 'mdi-shield-account',
          title: 'listCustomers',
          to: '/list-customer',
        },
        {
          icon: 'mdi-hammer-wrench',
          title: 'Configuração de servidores',
          to: '/config-servers',
        },
        // {
        //   icon: 'mdi-bookshelf',
        //   title: 'listCustomersRisc',
        //   to: '/list-customer-risc',
        // },
        {
          icon: 'mdi-puzzle',
          title: 'atualizacoes',
          to: '/atualizacoes',
        },
        {
          icon: 'mdi-file-document',
          title: 'awsFiles',
          to: '/backup/files',
        },
        {
          icon: 'mdi-card-account-details-outline',
          title: 'listUsers',
          to: '/users',
        },
        {
          icon: 'mdi-frequently-asked-questions',
          title: 'faq',
          to: '/faq',
        },
      ],
    }),

    computed: {
      ...mapState('dashboard', ['drawer']),
      ...mapGetters('dashboard', ['getDrawer']),
      computedItems () {
        return this.items.map(this.mapItem)
      },
      drawerView: {
        get () {
          return this.getDrawer
        },
        set (val) {
          this.generateDrawer(val)
        },
      },
      profile () {
        return {
          avatar: true,
          title: 'Clinic',
        }
      },
      themeMode () {
        return this.$vuetify.theme.dark
      },
    },
    watch: {
      themeMode: function (value) {
        localStorage.setItem('isDarkMode', value)
      },
    },

    methods: {
      ...mapActions('dashboard', ['generateDrawer']),
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
